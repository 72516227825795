import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/layout/home/home.component';
import { DentalIndividualComponent } from 'src/layout/dental-individual/dental-individual.component';
import { PmeDentalComponent } from 'src/layout/pme-dental/pme-dental.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'dental-individual', component: DentalIndividualComponent },
  { path: 'pme-dental', component: PmeDentalComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
