<div class="img">
  <img class="img-fluid" src="https://s3-sa-east-1.amazonaws.com/cmsescale-static/wp-content/uploads/sites/35/2018/10/12184003/planos-odontologicos-infantil_desk1.jpg" alt="">
</div>
<div class="container">
  <h2>Amil Dental Kids</h2>
  <p>
    A Odontopediatria é a especialidade que cuida da saúde bucal do seu filho desde a amamentação.
    Com um plano da Linha Kids, você conta com os melhores especialistas da área e uma cobertura perfeita para o
    seu filho sorrir desde os primeiros meses de vida.
  </p>
  <b>Conheça nossos benefícios:</b>
  <br>
  <br>
  <ul>
    <li>Rede diferenciada de especialistas em Odontopediatria;</li>
    <li>Programas periódicos de prevenção e promoção da saúde bucal para crianças;</li>
    <li>Sem limite de utilização;</li>
    <li>Aprovação online dos tratamentos cobertos;</li>
    <li>Clínicas de urgência 24h;</li>
    <li>Carteirinha virtual;</li>
    <li>Carência de apenas 24h para pagamentos no cartão de crédito ou boleto anual;</li>
    <li>Aditivo SOS Dental - atendimento domiciliar em casos de urgência.</li>
  </ul>
    <p>
      <b >Contratação totalmente online. Mais facilidade para você.</b>
      </p><ul>
        <li>É simples e rápido. Toda a compra é realizada em apenas 3 passos;</li>
        <li>Parcelamento em 1, 2, 3, 4, 6 ou 12 vezes, sem juros, nos cartões Visa, Mastercard, Elo e American Express;</li>
        <li>Burocracia zero - você não precisa enviar nenhum documento para a Amil Dental no ato da contratação <span style="display:inline; font-size: 11px;">(3)</span>.</li>
      </ul>
    <p>

  <b>Contate o Corretor</b>
  <div class="card">
    <div class="corretor">
      <p>Neli Ferraz Azevedo Seguros</p>
      <p>(15) 99148-5415</p>
      <a href="mailto:neli@abrintsegben.com" title="neli@abrintsegben.com" style="color:#00c1de; text-decoration:none;">neli@abrintsegben.com</a>
      <br>
      <a href="http://www.abrintseguros.com.br/" title="www.abrintseguros.com.br" target="_blank" style="color:#00c1de; text-decoration:none;">www.abrintseguros.com.br</a>
    </div>
    <div class="oferta">
      <ul>
        <li>Odontopediatria</li>
        <li>Instalação de aparelho</li>
        <li>Urgência 24 horas</li>
      </ul>
    </div>
    <div class="saibamais">
      A partir de:<br>
      <p class="value">R$29,90 por vida</p>
      <a href="https://www.amildental.com.br/plano-odontologico/dental-kids-k20/961628_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
    </div>
  </div>
</div>


