<div class="banner">
  <div class="content">
    <h1>Plano Amil Dental PME</h1>
    <p>A Amil Dental oferece o que existe de mais avançado na odontologia e uma rede credenciada com mais de 37 mil opções de atendimento em todo o país.</p>
    <div class="btn-content">
      <a href="pme-dental#anchor1">Conheça o plano</a>
    </div>
  </div>
  <div class="banner-front"></div>
  <img src="./../../assets/images/banner-01.jpg">
</div>

<div class="container pme-dental"  id="anchor1">
  <h2>Principais planos odontológicos Amil PME</h2>
  <p>Confira abaixo algumas opções dos planos Amil PME</p>
  <div class="cards">
    <div class="card">
      <div class="card-header">
        <h2>Dental 200 PME</h2>
      </div>
      <div class="card-body">
        <li>Limpeza, prevenção e aplicação de flúor (profilaxia)</li>
        <li>Tratamento de canal (endodontia)</li>
        <li>Restaurações (dentística)</li>
        <li>Cirurgias, inclusive retirada de Siso</li>
        <li>Raios X (panorâmicos e periapicais)</li>
        <li>Urgência/Emergência</li>
        <p>A partir de <b>R$23,00</b></p>
      </div>
      <div class="card-footer">
        <a href="https://www.amildental.com.br/plano-odontologico/dental-200-doc-pme/961633_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h2>Dental E30 PME</h2>
      </div>
      <div class="card-body">
        <li>Rol ANS ampliado	</li>
        <li>Coroas Unitárias em resina (todos os dentes)	</li>
        <li>Prótese Total (Dentaduras)</li>
        <li>Prótese Parcial Removível provisória</li>
        <li>Prótese Parcial Removível com grampo (Ponte removível)</li>
        <li>Placa Miorrelaxante (Placa de ATM ou Bruxismo)</li>
        <li>Clareamento dentário Convencional (Gel e Moldeiras)</li>

        <p>A partir de <b>R$99,00</b></p>
      </div>
      <div class="card-footer">
        <a href="https://www.amildental.com.br/plano-odontologico/dental-e30-win-pr%C3%B3tese-pme/961634_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h2>Dental E40 PME</h2>
      </div>
      <div class="card-body">
        <li>Rol ANS ampliado</li>
        <li>Documentação ortodôntica (Completa)</li>
        <li>Ortodontia (Aparelho metálico)</li>
        <li>Manutenções Ortodônticas</li>
        <li>Clareamento dentário Convencional (Gel e Moldeiras)</li>

        <p>A partir de <b>R$123,00</b></p>
      </div>
      <div class="card-footer">
        <a href="https://www.amildental.com.br/plano-odontologico/dental-e40-pme/961635_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
      </div>
    </div>
</div>

<h2>Amil Dental PME</h2>
<p>A Amil acredita que o cuidado com a saúde deve ser completo. Por isso, ela criou um plano odontológico com a mesma força de sua marca, garantindo um tratamento integrado ao cliente com a máxima qualidade.</p>
<p>A Amil Dental PME oferece o que existe de mais avançado na odontologia e uma rede credenciada com mais de 37 mil opções de atendimento em todo o país.</p>
</div>
