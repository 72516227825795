<div class="img">
  <img class="img-fluid" src="https://s3-sa-east-1.amazonaws.com/cmsescale-static/wp-content/uploads/sites/35/2018/10/12183941/planos-odontologicos-individual_desktop1.jpg" alt="">
</div>
<div class="container dental-individual">
    <h2>Principais planos odontológicos individuais</h2>
    <p>Confira abaixo algumas opções dos planos individuais</p>
    <div class="cards">
      <div class="card">
        <div class="card-header">
          <h2>Básico</h2>
        </div>
        <div class="card-body">
          <li>Cirurgias e extrações (incluindo o dente do siso)</li>
          <li>Odontologia Preventiva</li>
          <li>Tratamento de Canal</li>
          <p>Diferencial: Odontologia preventiva, urgência / emergência e cirurgias</p>
          <p>A partir de <b>R$46,50</b></p>
        </div>
        <div class="card-footer">
          <a href="https://www.amildental.com.br/plano-odontologico/dental-200-para-1-a-2-pessoas/961629_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>Melhor custo-benefício</h2>
        </div>
        <div class="card-body">
          <li>Prótese Estética</li>
          <li>Clareamento</li>
          <li>Restaurações em resina</li>
          <p>Diferencial: Instalação de próteses estéticas e clareamento estético</p>
          <p>A partir de <b>R$115,00</b></p>
        </div>
        <div class="card-footer">
          <a href="https://www.amildental.com.br/plano-odontologico/dental-e30-win-pr%C3%B3tese-pf/961631_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>Completo</h2>
        </div>
        <div class="card-body">
          <li>Aparelho metálico e removível</li>
          <li>Clareamento</li>
          <li>Documentação e manutenção Ortodôntica</li>
          <p>Diferencial:  Cobertura para diversas próteses e restaurações</p>
          <p>A partir de <b>R$147,00</b></p>
        </div>
        <div class="card-footer">
          <a href="https://www.amildental.com.br/plano-odontologico/dental-e40-win-orto-pf/961632_DENTAL?corretora=073660&codigoCorretor=83877517820&supervisor.corretor.codigoCorretor=83877517820&utm_source=comunicaamil&utm_medium=email&utm_campaign=073660">Saiba Mais</a>
        </div>
      </div>
  </div>

  <h2>Amil Dental Individual</h2>
  <p>A Amil Dental está no mercado de serviços odontológicos há 30 anos e conta com uma rede credenciada com mais de 40 mil opções para você.</p>
  <p>Nossos planos odontológicos individuais – Amil Dental 50 NE, 200 Doc, Orto e Win Prótese -, além da cobertura para o rol de procedimentos da ANS, conta com cobertura extra para os mais importantes procedimentos odontológicos.</p>
  <p>Seja um procedimento de urgência, colocação e mantenimento de aparelho ou de próteses, saiba que você pode contar com a Amil Dental. Conheça nossos planos odontológicos individuais e veja qual melhor se encaixa em suas necessidades.</p>
</div>
