<nav class="nav">
  <div class="container">
      <a href="http://www.abrintseguros.com.br" target="_blank">
        <img class="logo" src="../../assets/images/logo-branco.png" alt="Abrint Seguros">
      </a>
      <div class="nav-items">
        <a routerLink="/home" routerLinkActive="router-link-active">Dental Infantil</a>
        <a routerLink="/dental-individual">Dental Individual</a>
        <a routerLink="/pme-dental">PME Dental</a>
      </div>
  </div>
</nav>
