import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-dental-individual',
  templateUrl: './dental-individual.component.html',
  styleUrls: ['./dental-individual.component.scss']
})
export class DentalIndividualComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
